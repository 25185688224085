import React from "react";
import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin";
import {publish} from "../../lib/MessageBus";
import * as moduleStyles from "./TrackingConsent.module.scss";
import { Link } from "gatsby";

// In private mode (Safari), setItem always throws an exception.
function consent() {
  try {
    localStorage.setItem(GTAG_OPTIN_KEY, "true");
    sessionStorage.removeItem(GTAG_OPTIN_KEY);
  } catch(e) {
    // Nothing we can do...
  }
  publish("gdpr", "consent", {consent: true});
  if (typeof window.loadGtag == "function") {
    window.loadGtag();
  }
}

function reject() {
  let hadConsent = false;
  try {
    hadConsent = String(localStorage.getItem(GTAG_OPTIN_KEY)) === "true";
    localStorage.removeItem(GTAG_OPTIN_KEY);
    sessionStorage.setItem(GTAG_OPTIN_KEY, "false");
  } catch(e) {
    // Nothing we can do...
  }
  publish("gdpr", "consent", {consent: false});
  if (hadConsent) {
    window.location.reload();
  }
}

export default function TrackingConsent({status}) {
  if (!(status === "request" || status === "request-consented" || status === "request-rejected")) return null;
  let rejectTitle = "No thanks";
  let consentTitle = "This is okay";
  if (status === "request-consented") {
    rejectTitle = "Deactivate";
    consentTitle = "Keep active"
  }
  return (
    <div className={moduleStyles.container}>
      <div className={moduleStyles.inner}>
        <p>
          We use Google Analytics to learn how our website is performing. All data is anonymous.&nbsp;
          <Link to="/privacy-policy" title="Privacy Policy">View privacy policy</Link>
        </p>
        <div className={moduleStyles.buttonBar}>
          <div className={moduleStyles.buttonNegative} onClick={reject}>{rejectTitle}</div>
          <div className={moduleStyles.buttonPositive} onClick={consent}>{consentTitle}</div>
        </div>
      </div>
    </div>
  )
}

TrackingConsent.defaultProps = {
  status: "unknown"
}
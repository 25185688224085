import React from "react";
import { Button } from "../../form/Button";
import Menu from "./Menu";
import * as moduleStyles from "./Header.module.scss";
import Logo from "../../icons/Logo";


export default function Desktop(props) {

  return (
    <div className={moduleStyles.desktop}>
      <div className={moduleStyles.inner}>
        <Button link to="/" title="Bravemark landing page" className={moduleStyles.logoContainer}>
          <Logo />
        </Button>
        <div className={moduleStyles.nav}>
          <Menu desktop />
        </div>
      </div>
    </div>
  )
}
import Postal from "postal";

export function publish(channel, topic, data) {
  Postal.publish({
    channel: channel,
    topic: topic,
    data: data || {}
  })      
}

export function subscribe(channel, topic, cb) {
  return Postal.subscribe({
    channel: channel,
    topic: topic,
    callback: function(data, envelope) {
      cb(data, envelope);
    }
  })
}

export function unsubscribe(subscription) {
  subscription.unsubscribe();
}
import React from "react"
import Footer from "./footer/Footer";
import TrackingConsent from "../gdpr/TrackingConsent";
import useGdprStatus from "../gdpr/useGdprStatus";
import Header from "./header/Header";
import DefaultMeta from "../meta/DefaultMeta";


export default function Page(props) {
  const {
    children,
    mainClasses = null,
  } = props

  const [gdprStatus, requestGdpr] = useGdprStatus();

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { requestGdpr });
    }
    return child;
  });
  
  return (
    <>
      <DefaultMeta />
      <TrackingConsent status={gdprStatus} />
      <Header />
      <main className={mainClasses}>
        {childrenWithProps}
      </main>
      <Footer onPrivacySettingsClick={requestGdpr} />
    </>
  );
}



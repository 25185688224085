import React, { useEffect, useState } from "react";
import { Button } from "../../form/Button";
import Logo from "../../icons/Logo";
import MenuIcon from "../../icons/Menu";
import useScrollListener from "../../../hooks/useScrollListener"
import * as moduleStyles from "./Header.module.scss";
import Menu from "./Menu";

export default function Mobile(props) {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    // Make sure to restore the overflow on destroy
    return () => hideOverflowOnBody(false)
  }, [])

  const scrollY = useScrollListener()

  const baseClasses = [
    moduleStyles.mobile,
    !showMenu && scrollY <= 10 ? moduleStyles.atTheTop : null,
    showMenu ? moduleStyles.showMenu : moduleStyles.hideMenu,
  ]
  
  useEffect(() => {
    if (showMenu) {
      // Disable scrolling underneath
      hideOverflowOnBody(true);
    } else {
      // Allow scrolling underneath
      hideOverflowOnBody(false);
    }  
  }, [showMenu])

  return (
    <div className={baseClasses.join(" ")}>
      <div className={moduleStyles.inner}>
        <Button link to="/" title="Bravemark landing page"
          className={moduleStyles.logoContainer}
          onClick={() => setShowMenu(false)}>
          <Logo />
        </Button>
        
        <MenuIcon color="var(--bw-white)" onClick={() => setShowMenu(!showMenu)} />
      </div>
      <div className={moduleStyles.menu}>
        <Menu mobile onAfterClick={() => setShowMenu(false)} />
      </div>
    </div>
  );
}

function hideOverflowOnBody(bool) {
  return bool
    ? document.body.classList.add("overflow-hidden")
    : document.body.classList.remove("overflow-hidden");
}
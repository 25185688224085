import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag-optin";
import React, { useEffect, useState } from "react";
import * as moduleStyles from "./Button.module.scss";

const THEMES = {
  GREY: moduleStyles.themeGrey,
  LIGHT: moduleStyles.themeLight,
  DARK: moduleStyles.themeDark,
  BLUE: moduleStyles.themeBlue,
  GREEN: moduleStyles.themeGreen,
}
const SIZES = {
  SMALL: moduleStyles.sizeSmall,
  MEDIUM: moduleStyles.sizeMedium,
  LARGE: moduleStyles.sizeLarge,
}

export function Button(props) {
  const {
    link,
    className,
    width = null,
    desktop = false, // prevents mobile full width
    theme = THEMES.BLUE,
    size = SIZES.MEDIUM,
    // shortcuts for themes and sizes
    primary = false,
    secondary = false,
    small = false,
    medium = false,
    large = false,
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [selectedSize, setSelectedSize] = useState(size);

  // Shortcuts to override themes
  useEffect(() => {
    if (primary) {
      setSelectedTheme(THEMES.BLUE);
    } else if (secondary) {
      setSelectedTheme(THEMES.LIGHT);
    } else {
      setSelectedTheme(theme);
    }
  }, [primary, secondary]);

  // Shortcuts to override sizes
  useEffect(() => {
    if (small) {
      setSelectedSize(SIZES.SMALL);
    } else if (medium) {
      setSelectedSize(SIZES.MEDIUM);
    } else if (large) {
      setSelectedSize(SIZES.LARGE);
    } else {
      setSelectedSize(size);
    }
  }, [small, medium, large]);

  const classes = [
    className,
  ]

  if (link) {
    classes.push(moduleStyles.link)
  } else {
    classes.push(...[
      moduleStyles.button,
      selectedTheme, selectedSize,
      // desktop ? moduleStyles.desktop : moduleStyles.mobile,
    ])
  }

  const styles = {
    width: width ? width : undefined,
  }
  
  return renderAsLink(props)
    ? <LinkElem {...props} className={classes.join(" ")} styles={styles} />
    : <ButtonElem {...props} className={classes.join(" ")} styles={styles} />;
}

function renderAsLink(props) {
  return !!props.to;
}

function ButtonElem(props) {
  const {
    className,
    styles,
    onClick,
    label,
    children,
  } = props;


  const classes = [
    className,
    // onClick === null ? moduleStyles.disabled : null,
  ]

  return (
    <div className={classes.join(" ")}
      style={styles}
      onClick={onClick}>
      {label || children}
    </div>
  );
}

function LinkElem(props) {
  const {
    className,
    styles,
    to,
    label,
    title,
    onClick, // Even links can have a onClick
    children,
  } = props;

  const classes = [
    className,
  ]

  const ComponentName = to.startsWith("/") ? Link : OutboundLink

  return (
    <ComponentName
      className={classes.join(" ")}
      style={styles}
      to={to} // For Link
      href={to} // For OutboundLink
      title={title || label}
      onClick={onClick}>
      {label || children}
    </ComponentName>
  );
}

Button.THEMES = THEMES;
Button.SIZES = SIZES;

import React from "react";
import { fillColor, PathIcon } from "./Icon";

// ri-menu-line
export default function MenuIcon(props) {
  const {
    colorBackground = "none",
  } = props

  return (
    <PathIcon {...props}>
      <path d="M0 0h24v24H0z" fill={colorBackground} />
      <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" fill={fillColor(props)} />
    </PathIcon>
  )
}
import React, {useEffect, useState} from "react";
import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin";
import {subscribe, unsubscribe} from "../../lib/MessageBus";

export default function useGdprStatus() {
  const [status, setStatus] = useState("unknown");
  
  function requestGdpr() {
    setStatus(`request-${status}`);
  }

  useEffect(() => {
    function checkStorage() {
      // We check local storage for consent.
      let val = String(localStorage.getItem(GTAG_OPTIN_KEY));
      if (val === "true") {
        setStatus("consented");
      } else {
        // We check session storage for rejected so we dont ask to often.
        // If the visitor returns later, the session will be closed and we ask again.
        let sessVal = String(sessionStorage.getItem(GTAG_OPTIN_KEY));
        if (sessVal === "false") {
          setStatus("rejected");
        } else {
          setStatus("request");
        }      
      }
    }

    function handleConsentChange(data) {
      if (data.consent === true) {
        setStatus("consented");
      } else if (data.consent === false) {
        setStatus("rejected");
      }
    }

    const subscription = subscribe("gdpr", "consent", handleConsentChange);

    if (status === "unknown") checkStorage();
    
    return function() {
      unsubscribe(subscription);
    }
  }, [status])

  return [status, requestGdpr];
}
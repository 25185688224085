// extracted by mini-css-extract-plugin
export var bwWhite = "Footer-module--bw-white--Obvq-";
export var bgBwWhite = "Footer-module--bg-bw-white--INj-+";
export var bwBlack = "Footer-module--bw-black--Xm4JW";
export var bgBwBlack = "Footer-module--bg-bw-black--NQEV1";
export var grey100 = "Footer-module--grey-100--FZzl3";
export var bgGrey100 = "Footer-module--bg-grey-100--GMYA5";
export var grey200 = "Footer-module--grey-200--MPf6p";
export var bgGrey200 = "Footer-module--bg-grey-200--wkRJe";
export var grey300 = "Footer-module--grey-300--l0k7V";
export var bgGrey300 = "Footer-module--bg-grey-300--CIvsR";
export var grey400 = "Footer-module--grey-400--shjg5";
export var bgGrey400 = "Footer-module--bg-grey-400--XwQk9";
export var grey500 = "Footer-module--grey-500--GeIb3";
export var bgGrey500 = "Footer-module--bg-grey-500--p4k0Z";
export var grey600 = "Footer-module--grey-600--yYCpl";
export var bgGrey600 = "Footer-module--bg-grey-600--RNLHE";
export var grey700 = "Footer-module--grey-700--O6nN0";
export var bgGrey700 = "Footer-module--bg-grey-700--oAVuL";
export var grey800 = "Footer-module--grey-800--rM7JG";
export var bgGrey800 = "Footer-module--bg-grey-800--GdxH0";
export var grey900 = "Footer-module--grey-900--IhjEv";
export var bgGrey900 = "Footer-module--bg-grey-900--tHY3N";
export var blue100 = "Footer-module--blue-100--F-C3l";
export var bgBlue100 = "Footer-module--bg-blue-100--GFSQh";
export var blue200 = "Footer-module--blue-200--770oW";
export var bgBlue200 = "Footer-module--bg-blue-200--HR8Pn";
export var blue300 = "Footer-module--blue-300--HyHNv";
export var bgBlue300 = "Footer-module--bg-blue-300--Q30Qz";
export var blue400 = "Footer-module--blue-400--QHMJ5";
export var bgBlue400 = "Footer-module--bg-blue-400--giucu";
export var blue500 = "Footer-module--blue-500--YxgA+";
export var bgBlue500 = "Footer-module--bg-blue-500--zNCI2";
export var blue600 = "Footer-module--blue-600--SIZrX";
export var bgBlue600 = "Footer-module--bg-blue-600--X85Ae";
export var blue700 = "Footer-module--blue-700--sqaID";
export var bgBlue700 = "Footer-module--bg-blue-700--givFS";
export var blue800 = "Footer-module--blue-800--dB7cz";
export var bgBlue800 = "Footer-module--bg-blue-800--Z+Qj1";
export var blue900 = "Footer-module--blue-900--uPtUO";
export var bgBlue900 = "Footer-module--bg-blue-900--FLz+1";
export var orange100 = "Footer-module--orange-100--C670t";
export var bgOrange100 = "Footer-module--bg-orange-100--IhWbm";
export var orange200 = "Footer-module--orange-200--pvbzz";
export var bgOrange200 = "Footer-module--bg-orange-200--bk9BO";
export var orange300 = "Footer-module--orange-300--4+9El";
export var bgOrange300 = "Footer-module--bg-orange-300--tIJaq";
export var orange400 = "Footer-module--orange-400--tLtcB";
export var bgOrange400 = "Footer-module--bg-orange-400--UBl7Z";
export var orange500 = "Footer-module--orange-500--SnHFe";
export var bgOrange500 = "Footer-module--bg-orange-500--fEwf8";
export var orange600 = "Footer-module--orange-600--YsqJ4";
export var bgOrange600 = "Footer-module--bg-orange-600--wayWh";
export var orange700 = "Footer-module--orange-700--Jk0+X";
export var bgOrange700 = "Footer-module--bg-orange-700--pW70f";
export var orange800 = "Footer-module--orange-800--XbT3C";
export var bgOrange800 = "Footer-module--bg-orange-800--8taGz";
export var orange900 = "Footer-module--orange-900--PU2p+";
export var bgOrange900 = "Footer-module--bg-orange-900--T7vCi";
export var yellow100 = "Footer-module--yellow-100--h8L92";
export var bgYellow100 = "Footer-module--bg-yellow-100--sM0wb";
export var yellow200 = "Footer-module--yellow-200---9ZsX";
export var bgYellow200 = "Footer-module--bg-yellow-200--gKklY";
export var yellow300 = "Footer-module--yellow-300--etX+W";
export var bgYellow300 = "Footer-module--bg-yellow-300--h1QM1";
export var yellow400 = "Footer-module--yellow-400--O2A+0";
export var bgYellow400 = "Footer-module--bg-yellow-400--sL-01";
export var yellow500 = "Footer-module--yellow-500--kTwaC";
export var bgYellow500 = "Footer-module--bg-yellow-500--T30Dd";
export var yellow600 = "Footer-module--yellow-600--i36Ke";
export var bgYellow600 = "Footer-module--bg-yellow-600--+BGzZ";
export var yellow700 = "Footer-module--yellow-700--dWT5Y";
export var bgYellow700 = "Footer-module--bg-yellow-700--W+wbU";
export var yellow800 = "Footer-module--yellow-800--fPUQ5";
export var bgYellow800 = "Footer-module--bg-yellow-800--LY2Vq";
export var yellow900 = "Footer-module--yellow-900--ZgZKd";
export var bgYellow900 = "Footer-module--bg-yellow-900--JOzOz";
export var green100 = "Footer-module--green-100--6O8Ze";
export var bgGreen100 = "Footer-module--bg-green-100--hXin+";
export var green200 = "Footer-module--green-200--e3ozD";
export var bgGreen200 = "Footer-module--bg-green-200--C6H9P";
export var green300 = "Footer-module--green-300--a+qxs";
export var bgGreen300 = "Footer-module--bg-green-300--6vIlX";
export var green400 = "Footer-module--green-400--H0ZbO";
export var bgGreen400 = "Footer-module--bg-green-400--Y3H8a";
export var green500 = "Footer-module--green-500---MU4m";
export var bgGreen500 = "Footer-module--bg-green-500--72ibv";
export var green600 = "Footer-module--green-600--RfvUN";
export var bgGreen600 = "Footer-module--bg-green-600--Gsopi";
export var green700 = "Footer-module--green-700--Po0kL";
export var bgGreen700 = "Footer-module--bg-green-700--n7jlj";
export var green800 = "Footer-module--green-800--XUxy9";
export var bgGreen800 = "Footer-module--bg-green-800--qblUF";
export var green900 = "Footer-module--green-900--No00K";
export var bgGreen900 = "Footer-module--bg-green-900--0zfbw";
export var mtNone = "Footer-module--mt-none--zKSEh";
export var mbNone = "Footer-module--mb-none--5sWfC";
export var mlNone = "Footer-module--ml-none--5xx+m";
export var mrNone = "Footer-module--mr-none--qpYiJ";
export var maxWidthNone = "Footer-module--max-width-none--zU6K5";
export var mtSpacingXxs = "Footer-module--mt-spacing-xxs---YPXf";
export var mbSpacingXxs = "Footer-module--mb-spacing-xxs--YRGQG";
export var mlSpacingXxs = "Footer-module--ml-spacing-xxs--WW1xq";
export var mrSpacingXxs = "Footer-module--mr-spacing-xxs--MpEdW";
export var maxWidthSpacingXxs = "Footer-module--max-width-spacing-xxs--M3RG2";
export var mtSpacingXs = "Footer-module--mt-spacing-xs--6fulC";
export var mbSpacingXs = "Footer-module--mb-spacing-xs--W57gP";
export var mlSpacingXs = "Footer-module--ml-spacing-xs--ho9Cd";
export var mrSpacingXs = "Footer-module--mr-spacing-xs--lIGSF";
export var maxWidthSpacingXs = "Footer-module--max-width-spacing-xs--KD-h+";
export var mtSpacingS = "Footer-module--mt-spacing-s--D41JT";
export var mbSpacingS = "Footer-module--mb-spacing-s--qoY74";
export var mlSpacingS = "Footer-module--ml-spacing-s--EbX9N";
export var mrSpacingS = "Footer-module--mr-spacing-s--s1mFL";
export var maxWidthSpacingS = "Footer-module--max-width-spacing-s--iNrol";
export var mtSpacingM = "Footer-module--mt-spacing-m--a+ztF";
export var mbSpacingM = "Footer-module--mb-spacing-m--CdiS2";
export var mlSpacingM = "Footer-module--ml-spacing-m--J5b4F";
export var mrSpacingM = "Footer-module--mr-spacing-m--6IKwQ";
export var maxWidthSpacingM = "Footer-module--max-width-spacing-m--xjxj8";
export var mtSpacingL = "Footer-module--mt-spacing-l--4mCh2";
export var mbSpacingL = "Footer-module--mb-spacing-l--6Z8MA";
export var mlSpacingL = "Footer-module--ml-spacing-l--na24t";
export var mrSpacingL = "Footer-module--mr-spacing-l--xTJ+a";
export var maxWidthSpacingL = "Footer-module--max-width-spacing-l--7oTTI";
export var mtSpacingXl = "Footer-module--mt-spacing-xl--nXU7V";
export var mbSpacingXl = "Footer-module--mb-spacing-xl--D5oLG";
export var mlSpacingXl = "Footer-module--ml-spacing-xl--H32W-";
export var mrSpacingXl = "Footer-module--mr-spacing-xl--Mm4cX";
export var maxWidthSpacingXl = "Footer-module--max-width-spacing-xl--NmfsE";
export var mtSpacingXxl = "Footer-module--mt-spacing-xxl--rZ4pE";
export var mbSpacingXxl = "Footer-module--mb-spacing-xxl--DQQGq";
export var mlSpacingXxl = "Footer-module--ml-spacing-xxl--Y5w4j";
export var mrSpacingXxl = "Footer-module--mr-spacing-xxl--Qdx3R";
export var maxWidthSpacingXxl = "Footer-module--max-width-spacing-xxl--UV8Y5";
export var mtSpacingScreenMax = "Footer-module--mt-spacing-screen-max--pJy00";
export var mbSpacingScreenMax = "Footer-module--mb-spacing-screen-max--1MS+f";
export var mlSpacingScreenMax = "Footer-module--ml-spacing-screen-max--S4lLO";
export var mrSpacingScreenMax = "Footer-module--mr-spacing-screen-max--vv2yq";
export var maxWidthSpacingScreenMax = "Footer-module--max-width-spacing-screen-max--bhILU";
export var mtSpacingScreenLarge = "Footer-module--mt-spacing-screen-large--yHCO5";
export var mbSpacingScreenLarge = "Footer-module--mb-spacing-screen-large--fQmM+";
export var mlSpacingScreenLarge = "Footer-module--ml-spacing-screen-large--ddHxs";
export var mrSpacingScreenLarge = "Footer-module--mr-spacing-screen-large--WJYGH";
export var maxWidthSpacingScreenLarge = "Footer-module--max-width-spacing-screen-large--FH1xU";
export var mtSpacingScreenBig = "Footer-module--mt-spacing-screen-big--YAqv2";
export var mbSpacingScreenBig = "Footer-module--mb-spacing-screen-big--WHZ1G";
export var mlSpacingScreenBig = "Footer-module--ml-spacing-screen-big--X4pUb";
export var mrSpacingScreenBig = "Footer-module--mr-spacing-screen-big--Mox-I";
export var maxWidthSpacingScreenBig = "Footer-module--max-width-spacing-screen-big--WPqwH";
export var mtSpacingScreenNormal = "Footer-module--mt-spacing-screen-normal--5y1mW";
export var mbSpacingScreenNormal = "Footer-module--mb-spacing-screen-normal--IYvrL";
export var mlSpacingScreenNormal = "Footer-module--ml-spacing-screen-normal--II-Pe";
export var mrSpacingScreenNormal = "Footer-module--mr-spacing-screen-normal--SNYyV";
export var maxWidthSpacingScreenNormal = "Footer-module--max-width-spacing-screen-normal--AiD06";
export var mtSpacingScreenSmall = "Footer-module--mt-spacing-screen-small--GmToR";
export var mbSpacingScreenSmall = "Footer-module--mb-spacing-screen-small--Zbecv";
export var mlSpacingScreenSmall = "Footer-module--ml-spacing-screen-small--A+XeZ";
export var mrSpacingScreenSmall = "Footer-module--mr-spacing-screen-small--emliA";
export var maxWidthSpacingScreenSmall = "Footer-module--max-width-spacing-screen-small--DxIVt";
export var title1 = "Footer-module--title1--XTfdn";
export var title2 = "Footer-module--title2--bQOWS";
export var title3 = "Footer-module--title3--ft7hw";
export var textLead = "Footer-module--text-lead--kMZVh";
export var textNormal = "Footer-module--text-normal--PnVso";
export var textSmall = "Footer-module--text-small--VrPyB";
export var textFootnote = "Footer-module--text-footnote--6vCKr";
export var fontBold = "Footer-module--font-bold--e9fiu";
export var fontNormal = "Footer-module--font-normal--8-hY1";
export var container = "Footer-module--container--wJW8F";
export var inner = "Footer-module--inner--bz94o";
export var nav = "Footer-module--nav--PH32s";
export var textualLinks = "Footer-module--textualLinks--+XMH6";
export var socialLinks = "Footer-module--socialLinks--nKLAt";
export var copyright = "Footer-module--copyright--3CoyA";
export var logo = "Footer-module--logo--7pWDV";
export var desktop = "Footer-module--desktop--mMaXj";
export var mobile = "Footer-module--mobile--UTMPa";
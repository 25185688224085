import React from "react";
import * as moduleStyles from "./Icon.module.scss";

export function PathIcon(props) {
  const {
    className,
    size = "24px",
    inFlow,
    onClick,
  } = props

  const classes = [className, moduleStyles.container,
    inFlow ? moduleStyles.inFlow : "",
    !onClick ? moduleStyles.notClickable : "",
    `size-${size}`,
  ]

  return (
    <svg className={classes.join(" ")}
      width={size} height={size} viewBox="0 0 24 24"
      onClick={onClick}>
      {props.children}
    </svg>
  );
}

export function fillColor(props) {
  const {
    onClick,
    color = "var(--grey-400)",
    colorDisabled = "var(--grey-200)",
  } = props

  return onClick ? color : colorDisabled
}
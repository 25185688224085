import React, {useEffect, useState} from "react"

export default function useScrollListener() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.scrollY);
    }
    
    window.addEventListener("scroll", handleScroll);
    handleScroll()

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [])

  return scrollY;
}

import React, { useEffect } from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import useWindowSize from "../../../hooks/useWindowSize";
import * as moduleStyles from "./Header.module.scss";

const MOBILE_CUTOFF = 800;

export default function Header(props) {
  const windowSize = useWindowSize();

  return (
    <div className={moduleStyles.container}>
      {windowSize.width > MOBILE_CUTOFF ? <Desktop {...props} /> : <Mobile {...props} /> }
    </div>
  )
}

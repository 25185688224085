// extracted by mini-css-extract-plugin
export var button = "Button-module--button--8dz-W";
export var link = "Button-module--link--mSQBv";
export var themeGrey = "Button-module--themeGrey--9CZBP";
export var themeLight = "Button-module--themeLight--vGIV-";
export var themeDark = "Button-module--themeDark--Gcy91";
export var themeBlue = "Button-module--themeBlue--i69vB";
export var themeGreen = "Button-module--themeGreen--FspOo";
export var sizeSmall = "Button-module--sizeSmall--NuhMy";
export var sizeMedium = "Button-module--sizeMedium--ZHoMT";
export var sizeLarge = "Button-module--sizeLarge--miXVc";
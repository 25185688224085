import React from "react";
import { useRive } from '@rive-app/react-canvas'

export default function Logo(props) {
  const {
    standStill = false,
    width, height,
  } = props

  const { rive, RiveComponent } = useRive({
    src: '/bravemark-logo.riv',
  });

  const handleMouseEnter = () => {
    rive && rive.play("HoverOn")
  }

  const riveProps = {
    onMouseEnter: !standStill && handleMouseEnter,
    ...props,
  }

  const riveComponent = <RiveComponent {...riveProps} animations="HoverOn" />

  return width && height ? (
    <div style={{width: width + "px", height: height + "px"}}>{riveComponent}</div>
  ) : riveComponent;
}
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";

const MOBILE_CUTOFF = 700

export default function Spacer(props) {
  const {
    amount,
    // Mobile amount is usually that much less:
    amountMobile = amount / 3,
    unit,
    hideOnNarrowDevices,
    className,
  } = props

  const windowSize = useWindowSize()

  const dynAmount = windowSize.width > MOBILE_CUTOFF ? amount : amountMobile

  let styles = {  
    width: "100%",
    height: `${dynAmount}${unit}`
  }  

  let classes = [
    className,
    hideOnNarrowDevices ? "u-hide-narrow" : null,
  ];

  return (
    <div className={classes.join(" ")} style={styles} />
  )
}

Spacer.defaultProps = {
  amount: "50",
  unit: "px",
  hideOnNarrowDevices: false
}